import {
  DefaultButton,
  Icon,
  mergeStyleSets, TooltipHost,
} from '@fluentui/react';
import React from 'react';

export default ({ styles }) => {
  const componentStyles = mergeStyleSets({
    applyIconButton: {
      outline: 'none !important',
      selectors: {
        ':hover': {
          color: 'rgb(0, 120, 212)',
        },
      },
      minWidth: '30px',
      width: '30px',
      height: '30px',
      marginTop: '1px',
      marginLeft: '5px',
    },
    applyIcon: {
      width: '20px !important',
      height: '20px !important',
    },
  });

  return (
    <TooltipHost
      content="Apply"
    >
      <DefaultButton
        style={styles?.button}
        className={componentStyles.applyIconButton}
        text={<Icon iconName="CheckMark" className={componentStyles.applyIcon} />}
        styles={{
          ...styles?.button,
          label: {
            fontSize: '20px',
          },
        }}
      />
    </TooltipHost>
  );
};
