/* eslint-disable no-console */
import Config from '../Config';
import keycloak from '../Keycloak';

const buildHeaders = () => {
  const headers = {
    // Accept: 'application/json',
    // 'Content-Type': 'text/plain; charset=utf-8',
    'Content-Type': 'text/plain',
    ...(Config.isModePublicWebApp() && { 'Authentication-Mode': 'Anonymous' }),
  };
  if (keycloak.authenticated && keycloak.token) {
    headers.Authorization = `Bearer ${keycloak.token}`;
  }
  return headers;
};

const makeRequest = ({
  path, method, data, server = window.env.REACT_APP_API_ROOT, params = {},
}) => {
  const url = `${server + path}?${new URLSearchParams(params)}`;
  const body = (typeof data === 'string') ? data : JSON.stringify(data);
  return fetch(url, {
    method: method.toUpperCase(),
    headers: buildHeaders(),
    body,
  })
    .then(async (response) => {
      console.log(response);
      let text = '';
      let json = {};
      try {
        text = await response.text();
      } catch (e) {
        console.log('Failed to parse body as text');
      }

      try {
        json = JSON.parse(text);
      } catch (e) {
        // Not JSON
        console.log('No JSON returned');
        json.code = response.status;
        if (text) {
          json.messages = [text];
        }
      }

      if (response.status >= 300) {
        if (!json.code) {
          json.code = response.status;
        }
        if (!json.messages && !json.message) {
          json.message = response.statusText;
        }
        if (!json.messages) {
          json.messages = [];
          if (json.message) {
            json.messages.push(...json.message.split('\n'));
          }
          if (json.warnings) {
            json.messages = [...json.messages, ...json.warnings];
          }
          if (json.error?.message) {
            json.messages.push(json.error.message);
          }
          if (response.status === 404 && !response.message && !json.message) {
            json.messages.push(text || 'Server endpoint not found');
          }
          if (response.status === 501 && !response.message) {
            json.messages.push(text || 'Server endpoint not implemented');
          }
          if (json.error?.code) {
            json.code = json.error.code;
          }
        }
      }
      console.log(json);
      json.responseStatus = response.status;
      return json;
    })
    .catch((error) => {
      console.log(error);
      let { message } = error;
      if (message === 'Failed to fetch') {
        message = 'Server is unavailable at this time, please try again later.';
      }
      return {
        code: '550',
        status: 'failure',
        messages: [message],
      };
    });
};

export default {
  makeRequest,
};
