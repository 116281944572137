import {
  mergeStyleSets, Stack, Text, TextField, TooltipHost,
} from '@fluentui/react';
import React from 'react';
import { EditIcon } from '@fluentui/react-icons';
import CheckmarkIconButton from './CheckmarkIconButton';

const styles = mergeStyleSets({
  editIcon: {
    display: 'inline-block',
    marginLeft: '10px',
    position: 'relative',
    top: '-10px',
    right: '-1px',
    fontSize: '80%',
  },

  textLabelWrapper: {
    selectors: {
      ':hover': {
        background: 'white',
        selectors: {
          svg: {
            color: 'rgb(0, 120, 212)',
          },
        },
      },
    },
    padding: '5px',
    cursor: 'pointer',
  },

  textLabel: {
    color: 'black',
    width: '100%',
    display: 'inline-block',
  },

});

export default (props) => {
  const {
    styleText,
    styleInput,
    initialValue,
    placeholder,
    getValue,
    setValue,
  } = props;

  const [text, setText] = React.useState(initialValue);
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const textFieldRef = React.useRef();

  React.useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [editMode]);

  let textToShow = getValue();
  if (!textToShow || textToShow.trim().length === 0) {
    textToShow = placeholder;
  }

  if (editMode) {
    return (
      <Stack horizontal>
        <TextField
          ref={textFieldRef}
          placeholder={placeholder}
          value={text}
          onChange={(event, value) => {
            setErrorMessage('');
            setText(value);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              event.target.blur();
            } else if (event.keyCode === 27) {
              setEditMode(false);
              setErrorMessage('');
              setText(getValue());
            }
          }}
          onBlur={() => {
            const newValue = text ? text.trim() : '';
            const actionResult = setValue(newValue);
            if (actionResult === undefined || actionResult === true || actionResult.success) {
              // Success is:
              // - nothing returned at all (does not care about errors)
              // - true returned
              // - object with 'success':'true' returned
              setEditMode(false);
            } else if (actionResult.error) {
              setErrorMessage(actionResult.error);
            }
          }}
          errorMessage={errorMessage}
          style={styleInput}
          styles={{
            root: {
              width: '100%',
            },
          }}
        />

        <CheckmarkIconButton />
      </Stack>
    );
  }
  return (
    <TooltipHost
      content="Click to edit"
    >
      <div className={styles.textLabelWrapper}>
        <Text
          className={styles.textLabel}
          style={styleText}
          onClick={() => {
            setEditMode(true);
          }}
        >
          {textToShow}
          <EditIcon className={styles.editIcon} />
        </Text>
      </div>
    </TooltipHost>
  );
};
