import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Reducer, defaultState } from './shared/state/Reducer';
import Store from './shared/state/Store';
import keycloak from './shared/Keycloak';
import Env from './shared/Env';
import LoadingScreen from './portal/LoadingScreen';
import Application from './Application';

initializeIcons('https://res-1.cdn.office.net/files/fabric/assets/icons/', {
  disableWarnings: true,
});

if (Env.KC_ENABLED) {
  ReactDOM.render(
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
      LoadingComponent={<LoadingScreen />}
      onEvent={(event, error) => {
        // console.log('onKeycloakEvent', event, error);
        if (event === 'onTokenExpired') {
          // console.log('token expired', keycloak.token);
          keycloak.updateToken(30).then(() => {
            // console.log('successfully get a new token', keycloak.token);
          }).catch((err) => {
            // console.log('failed to refresh token');
            // console.log(err);
            keycloak.login({
              prompt: 'none',
            });
          });
        }
      }}
    >
      <BrowserRouter>
        <Store.StoreProvider initialState={defaultState} reducer={Reducer}>
          <Application />
        </Store.StoreProvider>
      </BrowserRouter>
    </ReactKeycloakProvider>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <Store.StoreProvider initialState={defaultState} reducer={Reducer}>
        <Application />
      </Store.StoreProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
